.s_logo img {
    width: 155px;
    padding: 5px;
}
.ant-layout-header {
    background-image: rgb(217,217,217)  !important;
    color: #ffffff;
}
.gx-header-horizontal-nav {
    background-color: #002849 !important;
    color: #fff !important;
    text-transform: uppercase;
}
.gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
    color:#fff !important;
}


.ant-menu-item .icon {
    font-size: 20px;
    color: #fff !important;
    font-weight: 800 !important;
}
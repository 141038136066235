
body{
  /*font-family: 'Roboto', sans-serif;*/
  /*font-family: 'Montserrat', sans-serif;*/
  /*font-weight: 500;*/
}

/* new dashboard css here */
.bg_1 {
  background-color: #6f42c1 !important;
  color: #fff;
}
.bg_2 {
  background-color: cornflowerblue !important;
  color: #fff;
}
.bg_3 {
  background-color: #e05f71 !important;
  color: #fff;
}
.bg_4 {
  background-color: #f3a533 !important;
  color: #fff;
}
.icon_color_1 {
  color: #6f42c1;
}
.icon_color_2 {
  color: #5aa4ed;
}
.icon_color_3 {
  color: #e05f71;
}

.icon_color_4 {
  color: #f3a533;
}

/* mini_cara_css */
.mini_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 20px;
  color: #000;
  margin-bottom: 5px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.min_card_icon {
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
}
.min_card_icon i {
  z-index: 99999;
  font-size: 22px !important;
  position: absolute;
  left: 8px;
  top: 8px;
}

.mini_card_title {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}
.mini_card_des {
  display: flex;
  align-items: center;
}
.mini_card_amount {
  font-size: 22px;
  font-weight: 700;
  margin-right: 3px;
}
.mini_card_des sub {
  text-transform: lowercase;
}
/* s_card_css */
.s_card {
  background-color: #fff;
  padding: 15px 18px;
  margin: 0px 0px 18px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: darkgrey;
  margin-bottom: 5px;
}
.bg_1 .s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: antiquewhite;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 1px;
}
.bg_2 .s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: antiquewhite;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 1px;
}
.bg_3 .s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: antiquewhite;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 1px;
}
.bg_4 .s_card_title {
  text-transform: capitalize;
  font-size: 14px;
  color: antiquewhite;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: 1px;
}


.s_dashboard_btn {
  margin: 2px 10px;
  border: none;
  color: #fff;
  text-transform: lowercase;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 13px;
  cursor: pointer;
}

.gx-card-widget .ant-card-body {
  padding: 24px ;
}

.s_single_membar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid  #999;
  padding-bottom: 14px;
  padding-top: 5px;
}
.personal_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.personal_info img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 17px;
}

.name {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}
.id ,.status{
  font-size: 10px;
  margin-top: 5px;
}
.getpoint {
  font-weight: 800;
  font-size: 21px;
  letter-spacing: 1px;
}

/* new dashboard css End */
.gx-card-widget {
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /*font-family: "Raleway", sans-serif;*/
  /*font-family: "Roboto", sans-serif;*/
}
.gx-card-metrics {
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /*font-family: "Raleway", sans-serif;*/
  /*font-family: "Roboto", sans-serif;*/
}
.gx-card {
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /*font-family: "Raleway", sans-serif;*/
  /*font-family: "Roboto", sans-serif;*/
  font-size: 14px;
  font-weight: 300;
}
.gx-fs-icon-lg {
  font-size: 21px !important;
}
.gx-fs-xl {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: cadetblue !important;
  margin-bottom: 14px !important;
}
.gx-media-body p {
  color: #989898;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.table > thead tr > th {
  color: #fff;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
}

.table > thead tr > th {
  color: #1b2b3a !important;
  text-align: left;
  /*padding: 10px 10px !important;*/
  /*background-color: none !important;*/
}
.table tbody tr td {
  /*padding: 8px !important;*/
  font-size: 12px !important;
  /*padding-left: 12px !important;*/
  /*color: #000000 !important;*/
  white-space: nowrap;
}
.sub-card {
  border: 1px solid rgba(245, 245, 245, 0.7) !important;
}

.dashboardWidgetClass .ant-card-head {
  background: white !important;
  color: #818a91 !important;
  font-weight: bold;
}

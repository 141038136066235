.s_logo img {
    width: 127px !important;
    padding: 5px;
}
.ant-layout-header {
    background-image: rgb(217,217,217)  !important;
    color: #ffffff;
}
.gx-header-horizontal-nav {
    background-color: #60738f !important;
    color: #fff !important;
}
.gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
    color:#fff !important;
}
/* rgb(230,230,230)
rgb(242,242,242) rgb(217,217,217)  */
.ant-menu-item .icon {
    font-size: 28px;
    color: #fff !important;
}
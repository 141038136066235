/*@import url("https://fonts.googleapis.com/css2?family=Luxurious+Roman&display=swap");*/
.s_form_area {
  display: flex;
  width: 100%;
  /* margin: 0px auto; */
  /* align-items: center; */
  /* justify-content: center; */
  height: 100vh;
}
.s_form_left {
  background-color: #fff !important;
  width: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  border-top-right-radius: 566px;
  border-right: 20px solid #87ceeb;
  position: relative;
}
.s_form_left::before{
  position: absolute;
  content: "";

}
/* .elememt1_img {
  background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('/assets/images/ele1.png');
}
.elememt2_img{
  background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('/assets/images/ele1.png');
}
.elememt3_img{
  background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('/assets/images/ele3.png');
} */
.btn_primary{
  font-weight: bold;
  color: #000;
}
.s_login_logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;

}
.s_form_right {
  width: 50%;
  background-color: #002849 !important;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-size: 17px;
  position: relative;
}

.copy_right_area {
  position: absolute;
  bottom: 15px;
  color: #000;
}
.copy_left_area {
  position: absolute;
  bottom: 10px;
  display: none;
}
.s_left_title {
  color: #fff;
  font-weight: 700;
  font-size: 27px;
  /*font-family: "Luxurious Roman", cursive;*/
}
.s_left_text p {
  color: #fff;
  font-size: 13px;
}
.s_left_text {
  text-align: center;
}
.ant-input {
  /*border: none !important;*/
  /*background: #fff !important;*/
  focus: none !important;
}
body {
  background-color: #002849 !important;
}
.ant-input-affix-wrapper > input.ant-input {
  font-size: 14px !important;
  font-weight: 300 !important;
}
.s_dashboard_btn {
  margin-right: 30px !important;
  border: none !important;
  color: #fff !important;
  text-transform: lowercase !important;
  padding: 5px 15px !important;
  border-radius: 3px !important;
  font-size: 13px !important;
  cursor: pointer !important;
}
.bg_2 {
  background-color: #696969 !important;
}
.sub_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  color: #fff;
  font-size: 20px;
}
.sub_link a{
  color: #fff !important;
  font-size:16px !important;
}
.ant-btn {
  line-height: 1.3;
  height: 30px !important;
}
.login-form-forgot {
  font-size: 10px;
  float: right;
  padding-right: 15px;
}
.s_btn {
  background-color: #bfedff;
  width: 100%;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
}
.s_btn:hover {
  background-color: aliceblue;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;}
.s_btn_area {
  margin-top: 44px;
  margin-right: 24px;
  display: block;
}
.s_or_signin {
  margin: 0 16px;
  text-transform: uppercase;
  color: #666669 !important;
  padding: 8px 14px;
  border-radius: 6px;
}
.s_or_signin:hover {
  background-color: #e8f0fe;
}
.s_demo_text {
  margin-top: 30px !important;
}
.s_form_right {
  width: 50%;
  background-color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .copy_right_area {
    display: none;
  }
  .copy_left_area {
    display: block;
    bottom: -84px;
  }
  .s_form_area {
    display: flex;
    flex-direction: column;
  }
  .s_login_logo {
    width: 200px;
  }
  .s_form_left {
    width: 100%;
    padding: 100px;
  }
  .s_form_right {
    margin-top: 44px;
  }
}

@media only screen and (max-width: 768px) {
  .copy_right_area {
    display: none;
  }
  .copy_left_area {
    display: block;
    bottom: -84px;
  }
  .s_form_area {
    display: flex;
    flex-direction: column;
  }
  .s_login_logo {
    width: 200px;
  }
  .s_form_left {
    width: 100%;
    padding: 100px;
  }
  .s_form_right {
    padding: 50px 58px;
    width: 100%;
    margin-top: 44px;

  }
}

@media only screen and (max-width: 992px) {
  .copy_right_area {
    display: none;
  }
  .copy_left_area {
    /* display: block; */
    bottom: -110px;
    font-size: 12px;
    text-align: center;
    left: 50%;
    transform: translate(-50%);
  }
  .s_form_area {
    display: flex;
    flex-direction: column;
  }
  .s_login_logo {
    width: 200px;
  }
  .s_form_left {
    width: 100%;
    padding: 100px;
  }
  .s_form_right {
    padding: 50px 40px;
    width: 100%;
    margin-top: 44px;

  }
}
